.brivity-theme {
  --button-primary-bg: var(--brivity-blue-100);
  --button-primary-bg-hover: var(--brivity-blue-110);
  --button-primary-bg-active: var(--brivity-blue-dark);
  --button-primary-bg-disabled: var(--brivity-blue-40);

  --button-primary-border-color: var(--brivity-blue-100);
  --button-primary-border-color-hover: var(--brivity-blue-110);
  --button-primary-border-color-active: var(--brivity-blue-dark);
  --button-primary-border-color-disabled: var(--brivity-blue-40);

  --button-secondary-color: var(--brivity-blue-100);
  --button-secondary-color-active: var(--brivity-blue-100);
  --button-secondary-color-disabled: var(--brivity-blue-40);

  --button-secondary-bg-hover: var(--brivity-blue-10);
  --button-secondary-bg-active: var(--brivity-blue-10);

  --button-secondary-border-color: var(--brivity-blue-100);
  --button-secondary-border-color-hover: var(--brivity-blue-100);
  --button-secondary-border-color-active: var(--brivity-blue-100);
  --button-secondary-border-color-disabled: var(--brivity-blue-40);

  --button-transparent-primary-bg-hover: var(--brivity-blue-100);
  --button-transparent-primary-bg-active: var(--brivity-blue-110);

  --button-transparent-primary-border-hover: var(--brivity-blue-100);
  --button-transparent-primary-border-active: var(--brivity-blue-110);

  --button-border-width-small: 1.5px;
  --button-border-width-small-hover: 1.5px;
  --button-border-width-small-active: 2px;

  --button-border-width-medium: 2px;
  --button-border-width-medium-hover: 2px;
  --button-border-width-medium-active: 3px;

  --button-horizontal-padding-small: 6.5px;
  --button-horizontal-padding-small-hover: 6.5px;
  --button-horizontal-padding-small-active: 6px;
  --button-vertical-padding-small: 2.5px;
  --button-vertical-padding-small-hover: 2.5px;
  --button-vertical-padding-small-active: 2px;

  --button-horizontal-padding-medium: 18px;
  --button-horizontal-padding-medium-hover: 18px;
  --button-horizontal-padding-medium-active: 17px;
  --button-vertical-padding-medium: 6px;
  --button-vertical-padding-medium-hover: 6px;
  --button-vertical-padding-medium-active: 5px;
}

.place-theme {
  --button-primary-bg: var(--place-black-100);
  --button-primary-bg-hover: var(--neutral-gray-100);
  --button-primary-bg-active: var(--semantic-blue-120);
  --button-primary-bg-disabled: var(--neutral-gray-30);

  --button-primary-border-color: var(--place-black-100);
  --button-primary-border-color-hover: var(--neutral-gray-100);
  --button-primary-border-color-active: var(--semantic-blue-120);
  --button-primary-border-color-disabled: var(--neutral-gray-30);

  --button-secondary-color: var(--place-black-100);
  --button-secondary-color-active: var(--semantic-blue-120);
  --button-secondary-color-disabled: var(--neutral-gray-30);

  --button-secondary-bg-hover: transparent;
  --button-secondary-bg-active: var(--semantic-blue-5);

  --button-secondary-border-color: var(--place-black-100);
  --button-secondary-border-color-hover: var(--place-black-100);
  --button-secondary-border-color-active: var(--semantic-blue-120);
  --button-secondary-border-color-disabled: var(--neutral-gray-30);

  --button-transparent-primary-bg-hover: var(--place-black-100);
  --button-transparent-primary-bg-active: var(--neutral-gray-100);

  --button-transparent-primary-border-hover: var(--place-black-100);
  --button-transparent-primary-border-active: var(--neutral-gray-100);

  --button-border-width-small: 1.5px;
  --button-border-width-small-hover: 2px;
  --button-border-width-small-active: 2px;

  --button-border-width-medium: 2px;
  --button-border-width-medium-hover: 3px;
  --button-border-width-medium-active: 3px;

  --button-horizontal-padding-small: 6.5px;
  --button-horizontal-padding-small-hover: 6px;
  --button-horizontal-padding-small-active: 6px;
  --button-vertical-padding-small: 2.5px;
  --button-vertical-padding-small-hover: 2px;
  --button-vertical-padding-small-active: 2px;

  --button-horizontal-padding-medium: 18px;
  --button-horizontal-padding-medium-hover: 17px;
  --button-horizontal-padding-medium-active: 17px;
  --button-vertical-padding-medium: 6px;
  --button-vertical-padding-medium-hover: 5px;
  --button-vertical-padding-medium-active: 5px;
}
