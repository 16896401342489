.themed-navbar {
  background-color: var(--navbar-background-color);
  border-bottom-color: var(--navbar-border-color);

  border-bottom-color: var(--navbar-border-color);
}

/* Search icon is styling with many selectors for specificity conflicts */
.themed-navbar #header form .fa-search {
  color: var(--navbar-search-icon-color);
}

.themed-navbar #header form:hover .fa-search {
  color: var(--navbar-search-icon-color-hover);
}

.themed-navbar #header form:focus-within .fa-search {
  color: var(--navbar-search-icon-color-active);
}

/* Classic VS New toggle */
/* ::before is Classic, while ::after is New */
.classic-toggle .btn-toggle::before {
  color: var(--navbar-classic-toggle-selected-text-color);
  opacity: 1;
}

.classic-toggle .btn-toggle::before {
  color: var(--navbar-classic-toggle-selected-text-color);
  opacity: 1;
}

/* With Classic selected */
.classic-toggle .btn-toggle[aria-pressed="false"] {
  background-color: var(--navbar-classic-toggle-background-color);
}

.classic-toggle .btn-toggle[aria-pressed="false"]::before {
  color: var(--navbar-classic-toggle-selected-text-color);
}

.classic-toggle .btn-toggle[aria-pressed="false"]::after {
  color: var(--navbar-classic-toggle-unselected-text-color);
}

/* With New selected */

.classic-toggle .btn-toggle[aria-pressed="true"] {
  background-color: var(--navbar-classic-toggle-background-color-checked);
}

.classic-toggle .btn-toggle[aria-pressed="true"]::before {
  color: var(--navbar-classic-toggle-unselected-text-color-checked);
}

.classic-toggle .btn-toggle[aria-pressed="true"]::after {
  color: var(--navbar-classic-toggle-selected-text-color-checked);
}

/* Icons */

.themed-navbar #brivity-help .fa-question-circle-o {
  color: var(--navbar-main-icon-color);
}

.navbar-fixed-top .notifications .icon {
  color: var(--navbar-main-icon-color);
}

.navbar-fixed-top .notifications .count {
  background-color: var(--navbar-notification-background-color);
}

/* User button */
button#accountDropdown {
  background-color: var(--navbar-user-button-background-color);
  color: var(--navbar-user-button-font-color);
}

button#accountDropdown:hover {
  background-color: var(--navbar-user-button-background-color-hover);
  color: var(--navbar-user-button-font-color-hover);
}

button#accountDropdown:focus-within {
  background-color: var(--navbar-user-button-background-color-active);
  color: var(--navbar-user-button-font-color-active);
}

button#accountDropdown .avatar-button-image-container {
  border-color: var(--navbar-user-button-avatar-border-color);
}

button#accountDropdown:hover .avatar-button-image-container {
  border-color: var(--navbar-user-button-avatar-border-color-hover);
}

button#accountDropdown:focus-within .avatar-button-image-container {
  border-color: var(--navbar-user-button-avatar-border-color-active);
}
