.brivity-theme {
  --dropdown-box-shadow-focus: var(--brivity-blue-20);
  --dropdown-border-hover: var(--brivity-blue-60);
  --dropdown-border-focus: var(--brivity-blue-100);
  --dropdown-input-selection: var(--brivity-blue-10);
}

.place-theme {
  --dropdown-box-shadow-focus: var(--semantic-blue-10);
  --dropdown-border-hover: var(--semantic-blue-50);
  --dropdown-border-focus: var(--semantic-blue-100);
  --dropdown-input-selection: var(--semantic-blue-5);
}
