.brivity-theme {
  --checkbox-bg-checked: var(--brivity-blue-100);
  --checkbox-bg-checked-hover: var(--brivity-blue-110);
  --checkbox-bg-indeterminate: var(--brivity-blue-100);
  --checkbox-bg-indeterminate-hover: var(--brivity-blue-110);

  --checkbox-border-hover: var(--brivity-blue-100);
  --checkbox-border-checked: var(--brivity-blue-100);
  --checkbox-border-checked-hover: var(--brivity-blue-110);
  --checkbox-border-indeterminate: var(--brivity-blue-100);
  --checkbox-border-indeterminate-hover: var(--brivity-blue-110);
}

.place-theme {
  --checkbox-bg-checked: var(--semantic-blue-100);
  --checkbox-bg-checked-hover: var(--semantic-blue-120);
  --checkbox-bg-indeterminate: var(--semantic-blue-100);
  --checkbox-bg-indeterminate-hover: var(--semantic-blue-120);

  --checkbox-border-hover: var(--semantic-blue-100);
  --checkbox-border-checked: var(--semantic-blue-100);
  --checkbox-border-checked-hover: var(--semantic-blue-120);
  --checkbox-border-indeterminate: var(--semantic-blue-100);
  --checkbox-border-indeterminate-hover: var(--semantic-blue-120);
}
