.brivity-theme {
  --totals-breakdown-bg-color: var(--white);
  --totals-breakdown-border-color: var(--white);
  --totals-breakdown-shadow: 0px 6px 20px 2px rgba(51, 51, 51, 0.16);
  --totals-breakdown-color: var(--neutral-gray-75);
}

.place-theme {
  --totals-breakdown-bg-color: var(--place-black-100);
  --totals-breakdown-border-color: var(--place-black-100);
  --totals-breakdown-shadow: 0px 1px 12px 5px rgba(51, 51, 51, 0.25);
  --totals-breakdown-color: var(--white);
}
