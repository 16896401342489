.brivity-theme {
  --tab-label-color-hover: var(--brivity-blue-100);
  --tab-label-color-focus: var(--brivity-blue-100);

  --tab-bg-focus: var(--brivity-blue-100);
}

.place-theme {
  --tab-label-color-hover: var(--place-black-100);
  --tab-label-color-focus: var(--place-black-100);

  --tab-bg-focus: var(--place-black-100);
}
