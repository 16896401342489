@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "themes.css";
@import "themed-navbar.css";
@import "themed-focus-indicator.css";
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,700,700italic,800,800italic,300,300italic,400italic,600,600italic);

@layer base {
  /* webkit only */
  .tw-scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  .tw-scrollbar-hidden {
    scrollbar-width: none;
  }

  .tw-outline-bootstrap:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  .tw-shadow-bootstrap-1 {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .tw-shadow-bootstrap-2 {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  }

  .tw-shadow-bootstrap-3 {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-shadow-bootstrap-3 {
      @apply tw-shadow-bootstrap-3;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-shadow-bootstrap-3 {
      @apply tw-shadow-bootstrap-3;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-shadow-bootstrap-3 {
      @apply tw-shadow-bootstrap-3;
    }
  }

  .tw-shadow-bootstrap-4 {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .tw-shadow-bootstrap-5 {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  .tw-shadow-bootstrap-6 {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  }

  .tw-shadow-bootstrap-7 {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  }

  .tw-shadow-bootstrap-8 {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  }

  .tw-shadow-bootstrap-9 {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .tw-shadow-bootstrap-10 {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 8px rgba(150, 150, 150, 0.6);
  }

  .focus\:tw-shadow-bootstrap-10:focus {
    @apply tw-shadow-bootstrap-10;
  }

  .tw-shadow-bootstrap-11 {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px #c0a16b;
  }

  .tw-shadow-bootstrap-12 {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px #ce8483;
  }

  .tw-shadow-bootstrap-13 {
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px #67b168;
  }

  .tw-shadow-bootstrap-14 {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .tw-shadow-bootstrap-15 {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  .active\:tw-shadow-bootstrap-15:active {
    @apply tw-shadow-bootstrap-15;
  }

  .tw-shadow-bootstrap-16 {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .tw-shadow-bootstrap-17 {
    box-shadow:
      inset 0 1px 0 rgba(255, 255, 255, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .tw-shadow-bootstrap-18 {
    box-shadow: 0px 6px 20px 2px rgba(51, 51, 51, 0.16);
  }

  .tw-shadow-19 {
    box-shadow: 0px 2px 8px rgba(213, 213, 213, 0.5);
  }

  .tw-shadow-20 {
    box-shadow: inset 0 1px 0 rgba(235, 235, 235, 1);
  }

  .tw-drop-shadow-19 {
    filter: drop-shadow(0px 2px 8px rgba(213, 213, 213, 0.5));
  }

  .tw-text-shadow-none {
    text-shadow: none;
  }

  .tw-text-shadow-bootstrap-1 {
    text-shadow: 0 1px 0 #fff;
  }

  .tw-text-shadow-bootstrap-2 {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  }

  .tw-bg-gradient-bootstrap-1 {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
  }

  .tw-bg-gradient-bootstrap-2 {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  }

  .tw-bg-gradient-bootstrap-3 {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  }

  .tw-filter-ms-gradient-false {
    filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  }

  .tw-important-text-black {
    @apply tw-text-black !important;
  }

  .tw-important-text-shadow-none {
    @apply tw-text-shadow-none !important;
  }

  .tw-important-bg-transparent {
    @apply tw-bg-transparent !important;
  }

  .tw-important-shadow-none {
    @apply tw-shadow-none !important;
  }

  .tw-important-max-w-full {
    @apply tw-max-w-full !important;
  }

  .tw-important-border-black {
    @apply tw-border-black !important;
  }

  .tw-important-border-collapse {
    @apply tw-border-collapse !important;
  }

  @media print {
    .bs-print\:tw-important-border-collapse {
      @apply tw-important-border-collapse;
    }
  }

  .tw-important-bg-white {
    @apply tw-bg-white !important;
  }

  .tw-important-border-color-ddd {
    @apply tw-border-color-ddd !important;
  }

  .tw-important-hidden {
    @apply tw-hidden !important;
  }

  @media (max-width: 767px) {
    .bs-xs\:tw-important-hidden {
      @apply tw-important-hidden;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-hidden {
      @apply tw-important-hidden;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-hidden {
      @apply tw-important-hidden;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-hidden {
      @apply tw-important-hidden;
    }
  }

  @media print {
    .bs-print\:tw-important-hidden {
      @apply tw-important-hidden;
    }
  }

  .tw-important-block {
    @apply tw-block !important;
  }

  @media (max-width: 767px) {
    .bs-xs\:tw-important-block {
      @apply tw-important-block;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-block {
      @apply tw-important-block;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-block {
      @apply tw-important-block;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-block {
      @apply tw-important-block;
    }
  }

  @media print {
    .bs-print\:tw-important-block {
      @apply tw-important-block;
    }
  }

  .tw-important-h-auto {
    @apply tw-h-auto !important;
  }

  .tw-important-overflow-visible {
    @apply tw-overflow-visible !important;
  }

  .tw-important-float-left {
    @apply tw-float-left !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-float-left {
      @apply tw-important-float-left;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-float-left {
      @apply tw-important-float-left;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-float-left {
      @apply tw-important-float-left;
    }
  }

  .tw-important-float-right {
    @apply tw-float-right !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-float-right {
      @apply tw-important-float-right;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-float-right {
      @apply tw-important-float-right;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-float-right {
      @apply tw-important-float-right;
    }
  }

  .tw-important-table {
    @apply tw-table !important;
  }

  .tw-important-table-row {
    @apply tw-table-row !important;
  }

  .tw-important-table-cell {
    @apply tw-table-cell !important;
  }

  .tw-important-inline {
    @apply tw-inline !important;
  }

  @media (max-width: 767px) {
    .bs-xs\:tw-important-inline {
      @apply tw-important-inline;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-inline {
      @apply tw-important-inline;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-inline {
      @apply tw-important-inline;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-inline {
      @apply tw-important-inline;
    }
  }

  @media print {
    .bs-print\:tw-important-inline {
      @apply tw-important-inline;
    }
  }

  .tw-important-inline-block {
    @apply tw-inline-block !important;
  }

  @media (max-width: 767px) {
    .bs-xs\:tw-important-inline-block {
      @apply tw-important-inline-block;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .bs-sm\:tw-important-inline-block {
      @apply tw-important-inline-block;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .bs-md\:tw-important-inline-block {
      @apply tw-important-inline-block;
    }
  }

  @media (min-width: 1200px) {
    .bs-lg\:tw-important-inline-block {
      @apply tw-important-inline-block;
    }
  }

  @media print {
    .bs-print\:tw-important-inline-block {
      @apply tw-important-inline-block;
    }
  }

  .tw-appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .tw-appearance-webkit-none {
    -webkit-appearance: none;
  }

  .tw-appearance-webkit-button {
    -webkit-appearance: button;
  }

  .tw-appearance-webkit-textfield {
    -webkit-appearance: textfield;
  }

  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }
  @keyframes progress-bar-stripes {
    from {
      background-position: 40px 0;
    }
    to {
      background-position: 0 0;
    }
  }

  .tw-animation-bootstrap-progress-bar-stripes {
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 2s linear infinite;
  }

  .tw-border-border-spacing-0 {
    border-spacing: 0;
  }

  .tw-clip-auto {
    clip: auto;
  }

  .active\:tw-clip-auto:active,
  .focus\:tw-clip-auto:focus {
    clip: auto;
  }

  .tw-clip-rect-0 {
    clip: rect(0, 0, 0, 0);
  }

  .tw-line-break-auto {
    line-break: auto;
  }

  .tw-orphans-3 {
    orphans: 3;
  }

  .tw-widows-3 {
    widows: 3;
  }

  .tw-page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .tw-page-break-after-avoid {
    page-break-after: avoid;
  }

  .tw-backface-visibility-hidden {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .tw-perspective-1000px {
    perspective: 1000px;
  }

  .tw--text-indent-999px {
    text-indent: -999px;
  }

  .tw-touch-action-manipulation {
    touch-action: manipulation;
  }

  .tw-word-spacing-normal {
    word-spacing: normal;
  }

  .tw-word-wrap-normal {
    word-wrap: normal;
  }

  .tw-word-wrap-break {
    word-wrap: break-word;
  }

  .tw-zoom-1 {
    zoom: 1;
  }

  /* FIXME: webpack is creating dynamic paths for the fonts with 'images' prefix */
  @font-face {
    font-family: "Glyphicons Halflings";
    src: url("fonts/glyphicons-halflings-regular.eot");
    src:
      url("fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"),
      url("fonts/glyphicons-halflings-regular.woff2") format("woff2"),
      url("fonts/glyphicons-halflings-regular.woff") format("woff"),
      url("fonts/glyphicons-halflings-regular.ttf") format("truetype"),
      url("fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
  }

  @-ms-viewport {
    width: device-width;
  }

  .tw-text-size-adjust-full {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  .tw-font-smoothing-antialiased {
    -webkit-font-smoothing: antialiased;
  }

  .tw-font-smoothing-grayscale {
    -moz-osx-font-smoothing: grayscale;
  }

  .tw-tap-highlight-color-transparent {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .tw-overflow-style-autohiding-scrollbar {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  @media (max-width: 767px) {
    .bs-xs\:tw-overflow-style-autohiding-scrollbar {
      @apply tw-overflow-style-autohiding-scrollbar;
    }
  }

  .tw-overflow-scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .tw-class-conflict {
    border: 4px solid red !important;
    border-radius: 0 !important;
  }

  .tw-bg-theme-checkbox-checked-mask {
    mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 14H1.5C0.671562 14 0 13.3284 0 12.5V1.5C0 0.671562 0.671562 0 1.5 0H12.5C13.3284 0 14 0.671562 14 1.5V12.5C14 13.3284 13.3284 14 12.5 14ZM6.10356 10.9357L11.8536 5.18566C12.0488 4.99041 12.0488 4.67381 11.8536 4.47856L11.1465 3.77147C10.9512 3.57622 10.6346 3.57619 10.4393 3.77147L5.75 8.46078L3.56066 6.27144C3.36541 6.07619 3.04881 6.07619 2.85353 6.27144L2.14644 6.97853C1.95119 7.17378 1.95119 7.49038 2.14644 7.68563L5.39644 10.9356C5.59172 11.1309 5.90828 11.1309 6.10356 10.9357Z' fill='%23000000'/%3E%3C/svg%3E");
  }

  .tw-bg-theme-checkbox-indeterminate-mask {
    mask: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.5 14H12.5C13.3284 14 14 13.3284 14 12.5V1.5C14 0.671562 13.3284 0 12.5 0H1.5C0.671562 0 0 0.671562 0 1.5V12.5C0 13.3284 0.671562 14 1.5 14ZM2.9375 6H11.0625C11.5801 6 12 6.41992 12 6.9375V7.5625C12 8.08008 11.5801 8.5 11.0625 8.5H2.9375C2.41992 8.5 2 8.08008 2 7.5625V6.9375C2 6.41992 2.41992 6 2.9375 6Z' fill='%23000000'/%3E%3C/svg%3E%0A");
  }

  /* Depends on Bootstrap's JavaScript that appends an .in class on elements with .fade classes */
  .tw-temp-fade.in {
    @apply tw-opacity-100;
  }

  /* Depends on Bootstrap's JavaScript that appends an .open class on parent elements and affects the .dropdown-menu display property */
  .open > .tw-temp-dropdown-menu {
    display: block;
  }

  .tw-temp-label-font-family {
    font-family: "Open Sans", sans-serif;
  }

  /* Applies classes to input and textarea on the read-only state */
  input.tw-read-only-form-control:read-only,
  textarea.tw-read-only-form-control:read-only {
    @apply tw-bg-gray-lighter;
    @apply tw-opacity-100;
  }

  /* Applies classes to label on the error state */
  [data-has-error="true"] label {
    @apply tw-text-color-a94442;
  }

  /* Applies classes to input and textarea on the error state */
  [data-has-error="true"] input,
  [data-has-error="true"] textarea {
    @apply tw-border-color-a94442;
    @apply tw-shadow-bootstrap-9;
  }

  /* Applies classes to input and textarea on the error state when focused */
  [data-has-error="true"] input:focus,
  [data-has-error="true"] textarea:focus {
    @apply tw-border-color-843534;
    @apply tw-shadow-bootstrap-12;
  }

  /* Helper class to change outline style when styles with precedence override the outline property. */
  .tw-outline-theme {
    outline: var(--focus-outline);
    outline-offset: var(--focus-outline-offset);
  }

  .tw-outline-theme-offset-1px {
    outline: var(--focus-outline);
    outline-offset: 1px;
  }

  .tw-outline-theme-offset-none {
    outline: var(--focus-outline);
    outline-offset: 0;
  }
}
