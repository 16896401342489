@import "./v1/component-themes.css";
@import "./v2/component-themes.css";
@import "../Transactions/component-themes.css";

.base-colors {
  /* Brivity Brand Colors */
  --brivity-blue-dark: #336E7B;
  --brivity-blue-110: #4CA7A7;
  --brivity-blue-100: #59C4C4;
  --brivity-blue-60: #94DCDC;
  --brivity-blue-40: #BDE7E7;
  --brivity-blue-20: #DEF3F3;
  --brivity-blue-10: #EEF9F9;

  --brivity-coral-dark: #CC472D;
  --brivity-coral-110: #D9593D;
  --brivity-coral-100: #F2714D;
  --brivity-coral-60: #F58966;
  --brivity-coral-40: #F9B099;
  --brivity-coral-20: #FCE3DB;
  --brivity-coral-10: #FCF1ED;

  /* Semantic Colors */
  --semantic-red-120: #CC2A25;
  --semantic-red-110: #E5342E;
  --semantic-red-100: #FA423C;
  --semantic-red-50: #FA5D57;
  --semantic-red-25: #FA9996;
  --semantic-red-10: #FAC5C3;
  --semantic-red-5: #FAE6E6;

  --semantic-orange-120: #CC5A21;
  --semantic-orange-110: #E56322;
  --semantic-orange-100: #FA7532;
  --semantic-orange-50: #FA8E58;
  --semantic-orange-25: #FAB796;
  --semantic-orange-10: #FAD5C3;
  --semantic-orange-5: #FAECE6;

  --semantic-yellow-120: #AD6714;
  --semantic-yellow-110: #E59722;
  --semantic-yellow-100: #FFA826;
  --semantic-yellow-50: #FAB957;
  --semantic-yellow-25: #FAD296;
  --semantic-yellow-10: #FAE4C3;
  --semantic-yellow-5: #FAF1E6;
  --accent-yellow: #FBC20D;

  --semantic-green-120: #32A658;
  --semantic-green-110: #36B25F;
  --semantic-green-100: #46C771;
  --semantic-green-50: #63D689;
  --semantic-green-25: #9DE0B4;
  --semantic-green-10: #CAF0D6;
  --semantic-green-5: #EDFAF1;

  --semantic-blue-120: #2454B2;
  --semantic-blue-110: #2E6CE5;
  --semantic-blue-100: #3270FA;
  --semantic-blue-50: #578EFA;
  --semantic-blue-25: #96B7FA;
  --semantic-blue-10: #C8D9FA;
  --semantic-blue-5: #E6EDFA;
  --semantic-blue-2_5: #F5F8FC;

  --semantic-purple-120: #6332C7;
  --semantic-purple-110: #6C36D9;
  --semantic-purple-100: #7532FA;
  --semantic-purple-50: #8E57FA;
  --semantic-purple-25: #B796FA;
  --semantic-purple-10: #D8C8FA;
  --semantic-purple-5: #ECE6FA;

  --semantic-pink-120: #D13282;
  --semantic-pink-110: #E5378E;
  --semantic-pink-100: #EB4698;
  --semantic-pink-50: #FA64AF;
  --semantic-pink-25: #FA96C8;
  --semantic-pink-10: #FAC8E1;
  --semantic-pink-5: #FAE6F0;

  /* Neutral Colors */
  --neutral-gray-100: #333333;
  --neutral-gray-75: #666666;
  --neutral-gray-50: #999999;
  --neutral-gray-30: #C2C2C2;
  --neutral-gray-15: #E0E0E0;
  --neutral-gray-10: #EBEBEB;
  --neutral-gray-5: #F5F5F5;
  --neutral-gray-2_5: #FAFAFA;
  --tinted-gray-100: #ECEFEF;
  --tinted-gray-50: #F5F7F7;
  --tinted-gray-25: #F9FBFB;

  /* odd bits */
  --white: #FFFFFF;
  --black: #000000;
  --place-black-100: #121212;

}

.brivity-theme {
  --primary-color: var(--brivity-blue-100);
  --primary-color-alternative: var(--brivity-blue-100);
  --primary-color-disabled: var(--brivity-blue-40);
  --brand: var(--brivity-blue-100);
  --brand-light: var(--brivity-blue-60);
  --brand-dark: var(--brivity-blue-110);

  /* sidebar */
  --navbar-background-color: var(--white);
  --navbar-border-color: #eeeeee;

  --navbar-font-color: #495057;
  --navbar-font-color-active: var(--brivity-blue-100);

  /* Menu option */
  --navbar-item-background-color: #eef9f9;
  --navbar-item-background-color-active: #eef9f9;
  --navbar-item-background-color-hover: #eef9f9;

  --navbar-item-font-color: #656565;
  --navbar-item-font-color-active: var(--brivity-blue-100);
  --navbar-item-font-color-hover: #151515;

  --navbar-item-icon-color: #656565;

  --navbar-item-top-border: #e9ecef;
  --navbar-item-left-border: var(--white);
  --navbar-item-left-border-active: var(--brivity-blue-100);
  --navbar-item-button-background-color: var(--neutral-gray-100);

  /* People Count Colors */
  --people-all-people-count-color: #336E7B;
  --people-sphere-count-color: #336E7B;
  --people-clients-count-color: #F4B350;
  --people-collaborators-count-color: #3B3B3B;
  --people-team-count-color: #59C4C4;
  --people-archived-count-color: #F2714D;

  /* Transaction Count Colors */
  --transaction-drafts-count-color: #F2714D;
  --transaction-coming-soon-count-color: #59C4C4;
  --transaction-active-count-color: #F4B350;
  --transaction-pending-count-color: #F2714D;
  --transaction-sold-count-color: #336E7B;
  --transaction-expired-count-color: #3B3B3B;
  --transaction-withdrawn-count-color: #3B3B3B;
  --transaction-canceled-count-color: #3B3B3B;
  --transaction-archived-count-color: #B3B3B3;

  /* Task Count Colors */
  --tasks-overdue-count-color: #F2714D;
  --tasks-due-today-count-color: #F4B350;
  --tasks-upcoming-count-color: #59C4C4;
  --tasks-completed-count-color: #3B3B3B;

  /* Circled plus */
  --navbar-item-plus-background-color: #f0f0f0;
  --navbar-item-plus-background-color-active: var(--brivity-blue-20);
  --navbar-item-plus-background-color-hover: var(--brivity-blue-20);

  --navbar-item-plus-font-color: #979797;
  --navbar-item-plus-font-color-active: var(--brivity-blue-100);
  --navbar-item-plus-font-color-hover: #151515;

  --navbar-item-button-background-color: var(--brivity-blue-20);

  /* Second expansion */
  --navbar-popout-title-font-color: var(--neutral-gray-75);
  --navbar-popout-item-font-color: var(--neutral-gray-75);
  --navbar-saved-filter-font-color: var(--neutral-gray-75);

  /* Submenu */
  --navbar-submenu-background-color: var(--white);
  --navbar-submenu-link-color: var(--neutral-gray-75);

  /* Topbar Search */
  --navbar-search-border-color: var(--neutral-gray-15);
  --navbar-search-border-color-active: var(--brivity-blue-100);
  --navbar-search-border-color-hover: var(--neutral-gray-75);

  --navbar-search-icon-color: #c2c2c2;
  --navbar-search-icon-color-active: var(--brivity-blue-100);
  --navbar-search-icon-color-hover: var(--neutral-gray-75);

  /* Classic Toggle */
  --navbar-classic-toggle-unselected-text-color: #c2c2c2;
  --navbar-classic-toggle-selected-text-color: var(--neutral-gray-50);

  --navbar-classic-toggle-unselected-text-color-checked: #c2c2c2;
  --navbar-classic-toggle-selected-text-color-checked: var(--neutral-gray-50);

  --navbar-classic-toggle-background-color: #c2c2c2;
  --navbar-classic-toggle-background-color-checked: var(--brivity-blue-100);

  /* Icons */
  --navbar-main-icon-color: var(--neutral-gray-50);
  --navbar-notification-background-color: var(--brivity-blue-100);

  /* User button */
  --navbar-user-button-background-color: var(--semantic-coral-20);
  --navbar-user-button-background-color-hover: var(--semantic-coral-40);
  --navbar-user-button-background-color-active: var(--semantic-coral-100);
  --navbar-user-button-font-color: var(--semantic-coral-100);
  --navbar-user-button-font-color-hover: var(--semantic-coral-100);
  --navbar-user-button-font-color-active: var(--white) fff;

  --navbar-user-button-avatar-border-color: var(--semantic-coral-100);
  --navbar-user-button-avatar-border-color-hover: var(--semantic-coral-100);
  --navbar-user-button-avatar-border-color-active: var(--semantic-coral-40);

  /* ? */
  --navbar-link-before-background-color: #868e96;
  --navbar-item-popout-link-color: #979797;
  --navbar-brand-padding: 0.7em 0 0.7em 0.8em;

  /* TDP */
  --tdp-financials-collapse-icon-color: var(--brivity-blue-100);
  --tdp-financials-collapse-icon-color-hover: var(--brivity-blue-110);
  --tdp-financials-collapse-icon-color-active: var(--brivity-blue-dark);

  --tdp-tasks-clear-filter-button-color: var(--brivity-blue-40);
}

.place-theme {
  --primary-color: var(--semantic-blue-100);
  --primary-color-alternative: var(--place-black-100);
  --primary-color-disabled: var(--semantic-blue-10);
  --brand: var(--semantic-blue-100);
  --brand-light: var(--semantic-blue-50);
  --brand-dark: var(--semantic-blue-110);

  /* sidebar */
  --navbar-background-color: var(--place-black-100);
  --navbar-border-color: var(--neutral-gray-100);

  --navbar-font-color: #c2c2c2;
  --navbar-font-color-active: var(--place-black-100);

  /* Menu option */
  --navbar-item-background-color: var(--place-black-100);
  --navbar-item-background-color-active: var(--white);
  --navbar-item-background-color-hover: var(--neutral-gray-100);

  --navbar-item-font-color: var(--white);
  --navbar-item-font-color-active: var(--place-black-100);
  --navbar-item-font-color-hover: var(--white);

  --navbar-item-icon-color: var(--white);

  --navbar-item-top-border: var(--neutral-gray-100);
  --navbar-item-left-border: var(--white);
  --navbar-item-left-border-active: var(--white);
  --navbar-item-button-background-color: var(--neutral-gray-100);

  /* Circled plus */
  --navbar-item-plus-background-color: var(--neutral-gray-100);
  --navbar-item-plus-background-color-active: var(--neutral-gray-10);
  --navbar-item-plus-background-color-hover: var(--neutral-gray-75);

  --navbar-item-plus-font-color: #c2c2c2;
  --navbar-item-plus-font-color-active: var(--neutral-gray-75);
  --navbar-item-plus-font-color-hover: var(--neutral-gray-15);

  /* Second expansion */
  --navbar-popout-title-font-color: var(--place-black-100);
  --navbar-popout-item-font-color: var(--neutral-gray-100);
  --navbar-saved-filter-font-color: var(--neutral-gray-100);

  /* Submenu */
  --navbar-submenu-background-color: var(--white);
  --navbar-submenu-link-color: var(--neutral-gray-100);

  /* Topbar Search */
  --navbar-search-border-color: var(--neutral-gray-75);
  --navbar-search-border-color-active: var(--semantic-blue-110);
  --navbar-search-border-color-hover: var(--neutral-gray-75);

  --navbar-search-icon-color: #c2c2c2;
  --navbar-search-icon-color-active: var(--semantic-blue-110);
  --navbar-search-icon-color-hover: var(--neutral-gray-75);

  /* Classic Toggle */
  --navbar-classic-toggle-unselected-text-color: var(--neutral-gray-50);
  --navbar-classic-toggle-selected-text-color: #c2c2c2;

  --navbar-classic-toggle-unselected-text-color-checked: var(--neutral-gray-50);
  --navbar-classic-toggle-selected-text-color-checked: var(--neutral-gray-10);

  --navbar-classic-toggle-background-color: var(--neutral-gray-75);
  --navbar-classic-toggle-background-color-checked: var(--semantic-blue-100);

  /* Icons */
  --navbar-main-icon-color: var(--neutral-gray-10);
  --navbar-notification-background-color: var(--semantic-blue-100);

  /* User button */
  --navbar-user-button-background-color: var(--neutral-gray-100);
  --navbar-user-button-background-color-hover: var(--neutral-gray-75);
  --navbar-user-button-background-color-active: var(--white);
  --navbar-user-button-font-color: var(--neutral-gray-15);
  --navbar-user-button-font-color-hover: var(--neutral-gray-15);
  --navbar-user-button-font-color-active: var(--neutral-gray-100);

  --navbar-user-button-avatar-border-color: var(--neutral-gray-10);
  --navbar-user-button-avatar-border-color-hover: var(--neutral-gray-10);
  --navbar-user-button-avatar-border-color-active: var(--semantic-blue-100);

  /* ? */
  --navbar-link-before-background-color: #868e96;
  --navbar-item-popout-link-color: var(--neutral-gray-75);
  --navbar-brand-padding: 0.7em 0 0.7em 16px;

  /* TDP */
  --tdp-financials-collapse-icon-color: var(--semantic-blue-100);
  --tdp-financials-collapse-icon-color-hover: var(--semantic-blue-110);
  --tdp-financials-collapse-icon-color-active: var(--semantic-blue-120);

  --tdp-tasks-clear-filter-button-color: var(--semantic-blue-25);
}
