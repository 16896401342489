.brivity-theme {
  --text-input-bg: var(--tinted-gray-50);
  --text-input-bg-hover: var(--tinted-gray-50);
  --text-input-bg-active: var(--white);
  --text-input-bg-disabled: var(--neutral-gray-2_5);

  --text-input-border: var(--neutral-gray-15);
  --text-input-border-hover: var(--brivity-blue-60);
  --text-input-border-active: var(--brivity-blue-100);
  --text-input-border-disabled: var(--neutral-gray-10);

  --text-input-ring-active: var(--brivity-blue-20);
}

.place-theme {
  --text-input-bg: var(--tinted-gray-50);
  --text-input-bg-hover: var(--tinted-gray-50);
  --text-input-bg-active: var(--white);
  --text-input-bg-disabled: var(--neutral-gray-2_5);

  --text-input-border: var(--neutral-gray-15);
  --text-input-border-hover: var(--semantic-blue-50);
  --text-input-border-active: var(--semantic-blue-100);
  --text-input-border-disabled: var(--neutral-gray-10);

  --text-input-ring-active: var(--semantic-blue-5);
}
