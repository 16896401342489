.brivity-theme {
  --account-avatar-border: var(--brivity-coral-100);
  --account-avatar-border-hover: var(--brivity-coral-60);
  --account-avatar-border-hover-md: var(--brivity-coral-100);
  --account-avatar-border-active: var(--brivity-coral-40);

  --account-avatar-bg: var(--brivity-coral-20);
  --account-avatar-bg-hover: var(--brivity-coral-40);
  --account-avatar-bg-active: var(--brivity-coral-100);

  --account-avatar-text: var(--brivity-coral-100);
  --account-avatar-text-hover: var(--brivity-coral-100);
  --account-avatar-text-active: var(--white);
}

.place-theme {
  --account-avatar-border: var(--neutral-gray-10);
  --account-avatar-border-hover: var(--neutral-gray-15);
  --account-avatar-border-hover-md: var(--neutral-gray-10);
  --account-avatar-border-active: var(--semantic-blue-100);

  --account-avatar-bg: var(--neutral-gray-100);
  --account-avatar-bg-hover: var(--neutral-gray-75);
  --account-avatar-bg-active: var(--white);

  --account-avatar-text: var(--neutral-gray-15);
  --account-avatar-text-hover: var(--neutral-gray-15);
  --account-avatar-text-active: var(--neutral-gray-100);
}
