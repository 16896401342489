.brivity-theme {
  --text-button-color: var(--brivity-blue-100);
  --text-button-color-hover: var(--brivity-blue-110);
  --text-button-color-focus: var(--brivity-blue-dark);
  --text-button-color-disabled: var(--brivity-blue-40);
}

.place-theme {
  --text-button-color: var(--semantic-blue-100);
  --text-button-color-hover: var(--semantic-blue-120);
  --text-button-color-focus: var(--place-black);
  --text-button-color-disabled: var(--semantic-blue-10);
}
