.brivity-theme {
  --divider-border-color: var(--brivity-blue-20);
  --cancel-icon-color-hover: var(--brivity-coral-100);
  --cancel-icon-color-active: var(--brivity-coral-110);
  --confirm-icon-color: var(--brivity-blue-100);
  --confirm-icon-color-hover: var(--brivity-blue-110);
  --confirm-icon-color-active: var(--brivity-blue-dark);
  --display-color-active: var(--brivity-blue-100);
}

.place-theme {
  --divider-border-color: var(--semantic-blue-5);
  --cancel-icon-color-hover: var(--semantic-red-50);
  --cancel-icon-color-active: var(--semantic-red-100);
  --confirm-icon-color: var(--semantic-blue-50);
  --confirm-icon-color-hover: var(--semantic-blue-100);
  --confirm-icon-color-active: var(--semantic-blue-120);
  --display-color-active: var(--semantic-blue-100);
}
