.brivity-theme {
  --icon-button-primary: var(--brivity-blue-100);
  --icon-button-primary-hover: var(--brivity-blue-110);
  --icon-button-primary-active: var(--brivity-blue-dark);
  --icon-button-primary-disabled: var(--brivity-blue-40);

  --icon-button-primary-text: white;
  --icon-button-primary-text-hover: white;
  --icon-button-primary-text-active: white;
  --icon-button-primary-text-disabled: white;
}

.place-theme {
  --icon-button-primary: var(--place-black-100);
  --icon-button-primary-hover: var(--neutral-gray-100);
  --icon-button-primary-active: var(--semantic-blue-120);
  --icon-button-primary-disabled: var(--neutral-gray-30);

  --icon-button-primary-text: white;
  --icon-button-primary-text-hover: white;
  --icon-button-primary-text-active: white;
  --icon-button-primary-text-disabled: white;
}
