.brivity-theme {
  --dateselector-text-color: var(--neutral-gray-75);
  --dateselector-main-color: var(--brivity-blue-100);
  --dateselector-secondary-color: var(--brivity-blue-100);
}

.place-theme {
  --dateselector-text-color: var(--place-black-100);
  --dateselector-main-color: var(--black);
  --dateselector-secondary-color: var(--semantic-blue-100);
}
