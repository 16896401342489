.brivity-theme {
  --filter-pill-hover: var(--brivity-blue-100);
  --filter-pill-selected: var(--brivity-blue-100);
  --filter-pill-selected-hover: var(--brivity-blue-110);
}

.place-theme {
  --filter-pill-hover: var(--place-black-100);
  --filter-pill-selected: var(--place-black-100);
  --filter-pill-selected-hover: var(--neutral-gray-100);
}
