.brivity-theme {
  --text-link-color: var(--brivity-blue-100);
  --text-link-color-hover: var(--brivity-blue-100);
  --text-link-color-active: var(--brivity-blue-dark);
  --text-link-color-visited: var(--brand);
}

.place-theme {
  --text-link-color: var(--semantic-blue-100);
  --text-link-color-hover: var(--semantic-blue-100);
  --text-link-color-active: var(--place-black-100);
  --text-link-color-visited: var(--brand);
}
