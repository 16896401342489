.brivity-theme {
  --type-cell-buyer-border-color: var(--semantic-blue-10);
  --type-cell-buyer-color: var(--semantic-blue-110);

  --type-cell-seller-border-color: var(--semantic-yellow-10);
  --type-cell-seller-color: var(--semantic-yellow-110);

  --type-cell-landlord-border-color: var(--semantic-red-10);
  --type-cell-landlord-color: var(--semantic-red-110);

  --type-cell-referral-border-color: var(--semantic-pink-10);
  --type-cell-referral-color: var(--semantic-pink-110);

  --type-cell-tenant-border-color: var(--semantic-green-10);
  --type-cell-tenant-color: var(--semantic-green-110);
}

.place-theme {
  --type-cell-buyer-border-color: var(--semantic-blue-120);
  --type-cell-buyer-color: var(--semantic-blue-50);

  --type-cell-seller-border-color: var(--semantic-yellow-120);
  --type-cell-seller-color: var(--semantic-yellow-100);

  --type-cell-landlord-border-color: var(--semantic-red-120);
  --type-cell-landlord-color: var(--semantic-red-50);

  --type-cell-referral-border-color: var(--semantic-pink-120);
  --type-cell-referral-color: var(--semantic-pink-50);

  --type-cell-tenant-border-color: var(--semantic-green-120);
  --type-cell-tenant-color: var(--semantic-green-50);
}
