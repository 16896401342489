.brivity-theme {
  --inline-editing-input-bg: var(--brivity-blue-10);
  --flat-percent-toggle-bg: var(--brivity-blue-20);
  --flat-percent-toggle-color-hover: var(--brivity-blue-100);
  --flat-percent-toggle-color-active: var(--brivity-blue-110);
  --flat-percent-toggle-color-selected: var(--brivity-blue-100);
}

.place-theme {
  --inline-editing-input-bg: var(--semantic-blue-2_5);
  --flat-percent-toggle-bg: var(--semantic-blue-5);
  --flat-percent-toggle-color-hover: var(--semantic-blue-50);
  --flat-percent-toggle-color-active: var(--semantic-blue-100);
  --flat-percent-toggle-color-selected: var(--semantic-blue-50);
}
