.brivity-theme {
  --focus-outline-color: var(--brivity-blue-40);
}

.place-theme {
  --focus-outline-color: var(--semantic-blue-10);
}

* {
  --focus-outline-width: 2px;
  --focus-outline-offset: 2px;
  --focus-outline: var(--focus-outline-color) auto var(--focus-outline-width);
}

*:focus-visible {
  outline: var(--focus-outline);
  outline-offset: var(--focus-outline-offset);
}
